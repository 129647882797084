<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Repository from "@/app/repository/repository-factory";
import gridSetup from "@/components/data/gridSetup";
import { roleService } from "@/app/service/roleService";
import { typeOfApplications } from "@/data/data-type-of-application-status";
import { dataTypeOfBankFiles } from "@/data/data-type-of-bank-files";
import { dataTypeOfBoolean } from "@/data/data-type-of-bank-accounts";

const PaymentBankRepository = Repository.get("PaymentBankRepository");
const PaymentBankFileTemplatesRepository = Repository.get("PaymentBankFileTemplatesRepository");

/**
 * Lista szablonów plików bankowych
 */
export default {
  page: {
    title: "Lista szablonów plików bankowych",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      ...gridSetup.data(),
      header: {
        title: "Lista szablonów plików bankowych",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista szablonów plików bankowych",
          },
        ],
      },
      fieldsSelectOptions: [],
      reportArray: [],
      rf: {
        Nazwa: "name",
        Skrót: "shortName",
        Kraj: "countryCode",
        SWIFT: "swift",
        Aktywny: {
          field: "isActive",
          callback: (value) => {
            return value ? "Tak" : "Nie";
          },
        },
      },
      bankSelectOptions: [],
      typeOfApplicationSelectOptions: typeOfApplications,
      isDefaultOptions: dataTypeOfBoolean,
      bankFileTypeOptions: dataTypeOfBankFiles,
    };
  },
  created() {
    this.getStateGridFilters();
    this.getBank();
  },
  methods: {
    fieldsComputed(fieldList) {
      let fieldsComputed = [];
      let fieldsSelected = [];
      const selectedCount = this.filters.grid.fieldsSelected.length;
      const defaultField = [
        "bankName",
        "fileType",
        "typeOfApplication",
        "typeOfTransfer",
        "isDefault",
        "isActive",
      ];

      // If selected is empty (eg. no save state)
      if (selectedCount === 0) {
        Object.entries(fieldList).forEach(([key, val]) => {
          if (defaultField.includes(val.key)) {
            fieldsSelected.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });

            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        });

        this.filters.grid.fieldsSelected = fieldsSelected;
      }

      // If selected is not empty
      if (selectedCount > 0) {
        Object.entries(this.filters.grid.fieldsSelected).forEach(
          ([key, val]) => {
            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        );
      }

      fieldsComputed.push({ key: "action", label: "Akcje", thClass: "last-col-sticky", tdClass: "last-col-sticky" });
      this.grid.fields = fieldsComputed;
    },
    gridProvider(ctx) {
      // if (
      //   localStorage.getItem("gridBankFilteTemplatesPaginationState") !== null
      // ) {
      //   ctx.currentPage = JSON.parse(
      //     localStorage.getItem("gridBankFilteTemplatesPaginationState")
      //   );
      // }

      // if (localStorage.getItem("gridBankFileTemplateSearchState") !== null) {
      //   this.filters.grid.filter = JSON.parse(
      //     localStorage.getItem("gridBankFileTemplateSearchState")
      //   );
      // }

      let fltrIsDe = this.filters.isDefault && this.filters.isDefault.description ? this.filters.isDefault.value : '';

      const parameters =
        "?page=" +
        ctx.currentPage +
        "&maxItems=" +
        ctx.perPage +
        "&search=" +
        this.filters.grid.filter +
        "&orderBy=" +
        this.filters.grid.sortBy +
        "&orderDesc=" +
        this.filters.grid.sortDesc +
        "&filterTypeOfApplication=" +
        this.filters.typeOfApplication.map((e) => e.index).join(",") +
        "&filterBank=" +
        this.filters.bank.map((e) => e.value).join(",") +
        "&isDefault=" +
        fltrIsDe +
        "&filterBankFileType=" +
        this.filters.fileType.map((e) => e.value).join(",");
      const promise = PaymentBankFileTemplatesRepository.getGrid(parameters);

      return promise
        .then((data) => {
          const fields = data.data.info.fields;
          this.grid.totalRows = data.data.info.countAllItems;

          // if (
          //   localStorage.getItem("gridBankFilteTemplatesPaginationState") !==
          //   null
          // ) {
          //   this.grid.currentPage = JSON.parse(
          //     localStorage.getItem("gridBankFilteTemplatesPaginationState")
          //   );
          // }

          let fieldsToSelect = [];

          Object.entries(fields).forEach(([key, val]) => {
            if (val.disabled !== true) {
              fieldsToSelect.push({
                text: val.label,
                value: key,
                label: val.label,
                key: key,
                sortable: val.sortable,
              });
            }
          });
          this.fieldsSelectOptions = fieldsToSelect;
          this.fieldsComputed(fieldsToSelect);

          this.reportArray = data.data.data || [];

          return this.reportArray;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    getBank() {
      PaymentBankRepository.getAll()
        .then((response) => {
          let bank = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            bank.push({
              text: response.data[i].name,
              value: response.data[i].shortName,
            });
          }

          this.bankSelectOptions = bank;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshGrid(value, id) {
      this.$refs.table_bank_file_template.refresh();
    },
    clearGridFiltersAndCols() {
      localStorage.removeItem("gridBankFilteTemplatesPaginationState");
      localStorage.removeItem("gridBankFileTemplateSearchState");
      this.grid.currentPage = 1;
      this.filters.typeOfApplication = [];
      this.filters.bank = [];
      this.filters.isDefault = [];
      this.filters.fileType = [];
      this.filters.grid.filter = "";
      this.filters.grid.filterOn = [""];
      this.filters.grid.perPage = 25;
      this.filters.grid.sortBy = "createdAt";
      this.filters.grid.sortDesc = true;
      this.filters.grid.fieldsSelected = [];
      this.saveStateGridFilters();
    },
    clearGridFilters() {
      this.grid.currentPage = 1;
      this.filters.typeOfApplication = [];
      this.filters.bank = [];
      this.filters.isDefault = [];
      this.filters.fileType = [];
      this.$refs.table_bank_file_template.refresh();
    },
    saveStateGridFilters() {
      localStorage.setItem(
        "gridBankFileTemplateListState",
        JSON.stringify(this.filters)
      );
      this.$refs.table_bank_file_template.refresh();

      Vue.swal({
        icon: "success",
        toast: false,
        position: "top",
        title: "SUKCES!",
        text: "Filtry zostały zapisane!",
        showConfirmButton: false,
        timer: 3500,
      });
    },
    getStateGridFilters() {
      if (localStorage.getItem("gridBankFileTemplateListState") !== null) {
        this.filters = JSON.parse(
          localStorage.getItem("gridBankFileTemplateListState")
        );
      }
    },
    clearAllTypeOfApplication() {
      this.filters.typeOfApplication = [];
      this.$refs.table_bank_file_template.refresh();
    },
    clearAllBank() {
      this.filters.bank = [];
      this.$refs.table_bank_file_template.refresh();
    },
    clearAllTypeOfFileType() {
      this.filters.fileType = [];
      this.$refs.table_bank_file_template.refresh();
    },
  },
  computed: {
    totalFiltersLength() {
      let f1 = this.filters.typeOfApplication ? this.filters.typeOfApplication.length : 0;
      let f2 = this.filters.bank ? this.filters.bank.length : 0;
      let f3 = this.filters.isDefault && this.filters.isDefault.description ? 1 : 0;
      let f4 = this.filters.fileType ? this.filters.fileType : 0;
      return f1 + f2 + f3 + f4 ;
    },
    isAdmin() {
      return roleService.isAdmin();
    },
  },
  watch: {
    // "grid.currentPage": function () {
    //   localStorage.setItem(
    //     "gridBankFilteTemplatesPaginationState",
    //     JSON.stringify(this.grid.currentPage)
    //   );
    // },
    // "filters.grid.filter": function () {
    //   localStorage.setItem(
    //     "gridBankFileTemplateSearchState",
    //     JSON.stringify(this.filters.grid.filter)
    //   );
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />

    <div class="row" v-if="isAdmin">
      <div class="col-12">
        <b-collapse id="collapse">
          <b-card class="shadow-sm">
            <div class="font-weight-bold" slot="header">Ustawienia kolumn i filtrów</div>
             <div class="form-group">
              <label class="typo__label">Wybierz widoczne kolumny tabeli</label>
              <multiselect v-model="filters.grid.fieldsSelected" :multiple="true" :options="fieldsSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz kolumny" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @input="fieldsComputed(fieldsSelectOptions)">
                <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
              </multiselect>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Wybierz bank</label>
                  <multiselect v-model="filters.bank" :multiple="true" :options="bankSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz bank" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych banków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.bank.length" @mousedown.prevent.stop="clearAllBank(props.search)"></div>
                    </template>
                   <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Rodzaj wniosku</label>
                  <multiselect v-model="filters.typeOfApplication" :multiple="true" :options="typeOfApplicationSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz typ wniosku" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych wniosków: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.typeOfApplication.length" @mousedown.prevent.stop="clearAllTypeOfApplication(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Domyślny</label>
                  <multiselect v-model="filters.isDefault" :options="isDefaultOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="typo__label">Typ szablonu</label>
                  <multiselect v-model="filters.fileType" :multiple="true" :options="bankFileTypeOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz typ wniosku" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych szablonów: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.fileType.length" @mousedown.prevent.stop="clearAllTypeOfFileType(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <button type="button" class="btn btn-danger waves-effect waves-light mr-2" v-on:click="clearGridFiltersAndCols">
                  <i class="mdi mdi mdi-trash-can-outline mr-1" /> Usuń ustawienia
                </button>
                <button type="button" class="btn btn-dark waves-effect waves-light mr-2" v-on:click="saveStateGridFilters">
                  <i class="dripicons-pin mr-1" /> Zapisz ustawienia
                </button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-warning waves-effect waves-light" v-on:click="clearGridFilters">
                  <i class="mdi mdi-filter-remove-outline mr-1" /> Wyczyść filtry
                </button>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">Banki</div>
              <div>
                <i class="mdi mdi-format-columns mr-1"></i>Kolumny: <span class="badge badge-pill badge-soft-info font-size-12">{{ filters.grid.fieldsSelected.length + '/' + fieldsSelectOptions.length}}</span>
                <span class="ml-2" v-if="totalFiltersLength > 0"><i class="mdi mdi-filter-outline mr-1"></i>Aktywne filtry: <span class="badge badge-pill badge-soft-success font-size-12">{{ totalFiltersLength }}</span></span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form inline>
                  <b-form-select v-model="filters.grid.perPage" :options="grid.pageOptions" class="w-auto" />
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.grid.filter" :debounce="350" placeholder="Szukaj..." :class="filters.grid.filter !='' ? 'border-success' : ''" />
                      <i class="ti-search search-icon" />
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="col-md-6">
                <div class="text-right mt-2 mt-md-0 mb-2">
                  <b-link v-if="isAdmin" :to="{ name: 'Dodaj szablon pliku bankowego' }" class="btn btn-dark waves-effect waves-light mb-2 mr-2">
                    <i class="mdi mdi-plus mr-1" />Dodaj szablon
                  </b-link>
                  <b-button v-b-toggle="'collapse'" class="waves-effect mb-2 collapsed" variant="success">
                    <i class="mdi mdi-filter-outline mr-1"></i>Filtry
                  </b-button>
                </div>
              </div>
            </div>
            <!-- End search -->

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table id="table_bank_file_template" ref="table_bank_file_template" :items="gridProvider" :fields="grid.fields" responsive="sm" sticky-header="45vh" sort-icon-left :per-page="filters.grid.perPage" :current-page="grid.currentPage" :sort-by.sync="filters.grid.sortBy" :sort-desc.sync="filters.grid.sortDesc" :filter="filters.grid.filter" :filter-included-fields="filters.grid.filterOn">
                <template v-slot:table-busy>
                  <div class="text-center text-black">
                    <b-spinner class="align-middle" />
                    <strong> Ładowanie danych...</strong>
                  </div>
                </template>

                <template v-slot:cell(typeOfApplication)="row">
                  <span class="text-nowrap">{{ row.item.typeOfApplication.name }}</span>
                </template>

                <template v-slot:cell(typeOfTransfer)="row">
                  <span class="text-nowrap">{{ row.item.typeOfTransfer.name }}</span>
                </template>

                <template v-slot:cell(isDefault)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-success': data.value == true, 'badge-soft-danger': data.value == false }">
                    {{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(isActive)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-success': data.value == true, 'badge-soft-danger': data.value == false }">
                    {{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(createdAt)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <template v-slot:cell(action)="row">
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2" v-if="isAdmin">
                      <a v-b-tooltip.hover.topleft title="Edycja danych">
                        <b-link :to="{ name: 'Edycja szablonu pliku bankowego', params: { id: row.item.bankFileTemplateId } }" class="grid-action-btn">
                          <i class="font-size-14 far fa-edit" />
                        </b-link>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col">
                <template v-if="grid.totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="grid.totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                    Znaleziono {{ grid.totalRows }} rekordów.
                  </div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-if="grid.totalRows > 0" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="filters.grid.perPage" first-number last-number />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
